import { useEffect, useState } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
// import EarthScene from '../Component/EarthScene';
// import Starfield from '../Component/Starfield';
import '../Styles/Home.css';
import Navbar from '../Component/Navbar';
import ServiceCard from '../Component/ServiceCard';
import airplane from '../Images/airplane.png';
import airplane2 from '../Images/airplane2.png';
import cloud1 from '../Images/cloud1.png';
import cloud2 from '../Images/cloud2.png';

export const HomePage = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/other-services');
  };
  const [scrollY, setScrollY] = useState(0);


  const handleScroll = () => {
    setScrollY(window.scrollY);
  };


  useEffect(() => {
    window.addEventListener('scroll', handleScroll);


    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const textOpacity = Math.max(1 - scrollY / 500, 0);

  const airplaneTranslateX = scrollY > 100 ? 2500 : 0;
  const airplaneTranslateY = scrollY * 1;


  const airplane2TranslateX = Math.max(0, 1500 - scrollY * 3);
  const airplane2TranslateY = scrollY * 0.004;


  return (
    <>
      <Navbar />
      <div className="container">
        <div className='home-container1'>
          <img
            src={airplane}
            alt="airplane"
            className="airplane"
            style={{
              transform: `translateX(${airplaneTranslateX}px) translateY(${airplaneTranslateY}px) translate(-50%, -50%)`,

            }}
          />
          <img src={cloud1} alt="cloud" className='cloud1' />
          <img src={cloud1} alt="cloud" className='cloud3' />
          <img src={cloud2} alt="cloud" className='cloud2' />
          <h1 style={{ opacity: textOpacity }}>Sagami Travels</h1>
          <div>
            <h3 style={{ opacity: textOpacity }}>Travel The World</h3>
            <h4 style={{ opacity: textOpacity }}> With us</h4>
          </div>
        </div>
        <div className='home-container2'>
          {/* <h2>Our Main Services</h2> */}
          <h5>Never Stop Exploring The World</h5>
          <div className='home-container3'>
            <div className='service-card-text'>
              <p>"Embark on unforgettable adventures with Sagami Travels. Our passion is to inspire and enable travelers to discover the beauty, wonder, and diversity of our planet. From hidden gems to iconic destinations, we're here to guide you on your journey of exploration. Let's create memories that last a lifetime."</p>
              <button onClick={handleButtonClick}>
                Other Services
              </button>
            </div>

            <div className="service-card-wrapper">
              <ServiceCard />
            </div>
          </div>
          <img
            src={airplane2}
            alt="airplane2"
            className="airplane2"
            style={{
              transform: `translateX(${airplane2TranslateX}px) translateY(${airplane2TranslateY}px) translate(-50%, -50%)`
            }}
          />
        </div>
      </div>
    </>
  );
};


