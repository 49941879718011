import React from 'react';
import '../Styles/Footer.css';
import logo from '../Images/sagami-logo.png';
import { FaFacebook, FaInstagram } from 'react-icons/fa'; 

const Footer = () => {
  return (
    <footer className="footer">

      <div className="footer-bottom">
        <div className="footer-links">

          <div className="link-column">
              <img src={logo} alt="logo" />
              <h2>Sagami Travels</h2>
          </div>

          <div className="link-column">
            <h4>Support</h4>
            <ul>
              <li><a href="/home">Home</a></li>
              <li><a href="/about">About Us</a></li>
              <li><a href="/contact">Contact Us</a></li>
            </ul>
          </div>

          <div className="link-column">
            <h4>Main Services</h4>
            <ul>
              <li><a href="/in-bound-tour">In Bound Tours</a></li>
              <li><a href="/out-bound-tour">Out Bound Tours</a></li>
            </ul>
          </div>

          <div className="link-column">
            <h4>Other Services</h4>
            <ul>
              <li><a href="/local-tour">Local Tours</a></li>
              <li><a href="#">Airline Ticketing</a></li>
              <li><a href="#">Cooperate Tours</a></li>
              <li><a href="#">Visa Services</a></li>
            </ul>
          </div>

          <div className='link-column1'>
            <h4>Follow us</h4>
            <div className='social-icon'>
              <FaFacebook className='facebook'/>
              <FaInstagram className='instagram' />
            </div>
          </div>
        </div>

        <div className="footer-info">
          <div className='footer-info1'>
            <h2>© 2024 Copyright Stalione Group</h2>
          </div>
          <div className='footer-info2'>
            <h2>Give Call Us:  (+94) 11 2769991/ (+94) 11 2769992</h2>
            <hr style={{color:'white', borderColor:'#ffffff44'}}/>
            <h2>No. 150, 1st floor, Dutugemunu Street, Kohuwala. (Opp. Odel)</h2>
          </div>
          
        </div>
      </div>
    </footer>
  );
};

export default Footer;
   