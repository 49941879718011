import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import '../../Styles/Detail/DetailsPage.css';
import Navbar from '../../Component/Navbar'
import calender from '../../Images/calender.png';
import time from '../../Images/time.png';
import call from '../../Images/call.png';
import HALONG from '../../Images/HALONG.jpg';
import cu from '../../Images/cu-chi.jpg';
import conatctcall from '../../Images/contact-call.png';
import Danang from '../../Images/Danang.jpg';
import Varanasi from '../../Images/Varanasi.jpg';
import Shimla from '../../Images/Shimla.jpg';
import kenya from '../../Images/kenya.jpg';

export const VietnamDetailsPage = () => {
  const handleClick = () => {
    const phoneNumber = '+94777790910'; 
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=Hello,%20I%20would%20like%20to%20get%20more%20details%20about%20your%20services.`; // Message to pre-fill

    window.open(whatsappUrl, '_blank'); 
  };

  
  const navigate = useNavigate(); 

  const handleNavigateToShimla = () => {
    navigate('/shimla-details'); 
  };
  const handleNavigateToKeniya = () => {
    navigate('/kenya-details'); 
  };
  const handleNavigateToDambadiva = () => {
    navigate('/dambadiva-details'); 
  };

  return (
    <>
     <Navbar/>
     <div className='details-section'>


      <div className='mainbar'>
        <img src={Danang} alt="Danang" />
        <div className='details'>
            <h1>VIETNAM TOUR</h1>
            <h2>Hanoi | Halong | Danang | Hoi An | Saigon</h2>
            <hr/>
        </div>
        <div className='content'>
            <h3>About Our VIETNAM Tour</h3>

            <div className='first-day'>
              <h4>Day 1 : HANOI Arrival & City Tour </h4>
              <div className='content-data1'>
                <h5>Welcome to Hanoi! After airport pickup, enjoy breakfast and visit the Ho Chi Minh Complex, including his Mausoleum, House on Stilts, and One Pillar Pagoda. After lunch and a rest, explore the Temple of Literature, Ngoc Son Temple, and Hoan Kiem Lake. Stay overnight in Hanoi.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 2 :	Hanoi-Hlong-Lan Ha Bay </h4>
              <div className='content-data'>
                <img src={HALONG} alt="HALONG" />
                <h5>After breakfast and check-out, travel from Hanoi to Halong Bay, arriving at Tuan Chau marina. Board the cruise, enjoy a buffet lunch, and explore Lan Ha Bay, including the Dark and Bright Cave. End the day with a sunset party, cooking class, and deluxe dinner, followed by leisure activities like squid fishing or karaoke. Stay overnight on the cruise.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 3	: Hlong Back to Hanoi / fly TO Danag </h4>
              <div className='content-data1'>
                <h5>Start the day with a sunrise Tai-chi session on the sundeck, followed by a light breakfast as the cruise sails through stunning karst formations. Enjoy kayaking and swimming at Frog Pond, then have lunch before disembarking and returning to Hanoi. In the afternoon, transfer to Noi Bai airport for a flight to Danang, where you'll check in at your hotel for the night.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 4	: Bana Hills With Golden Bridge, Drive to Hoi an </h4>
              <div className='content-data1'>
                <h5>After breakfast, a tour guide will pick you up and take you to Ba Na Hills, where you can enjoy a scenic cable car ride and visit old French villas and the stunning suspension bridge. After lunch with spectacular views, explore the famous Golden Bridge and the Vong Nguyet villa. Finally, drive to Hoi An for hotel check-in and an overnight stay.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 5 :	Hoi an Ancient Town/fly to Saigon </h4>
              <div className='content-data1'>
                <h5>After breakfast and check-out, explore Hoi An Ancient Town with a guide, visiting merchant houses, Chinese Assembly Halls, and the iconic Japanese Covered Bridge. Following this cultural experience, head to the airport for your flight to Saigon. Upon arrival, you will be picked up and transferred to your hotel for an overnight stay.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 6 :	Cu Chi Tunnles & City Tour  </h4>
              <div className='content-data'>
                <img src={cu} alt="cu" />
                <h5>After breakfast, visit the Cu Chi Tunnels to learn about their role in the Vietnam War, including exploring trap doors and storage facilities. Enjoy local food before heading back to Saigon to tour the Reunification Palace, War Remnants Museum, Notre Dame Cathedral, and the Old Post Office. Overnight stay in Saigon.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 7 :	Saigon Departure  </h4>
              <div className='content-data1'>
                <h5>Enjoy breakfast at hotel. Free time to explore Saigon. Driver pick up at hotel and transfer to Tan Son Nhat Airport for flight back home. See you next trip!</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

        </div>
        <div className='more-button'>
              <h3>Get more Deatils Contact Us</h3>
              <div onClick={handleClick} className="myButton">
                <h6>Call Us</h6>
                <img src={conatctcall} alt="conatct-call" className='conatct-call'/>
            </div>
        </div>
      </div>


      <div className='sidebar'>
      <div className='time-date'>
        <h1>Need help booking?</h1>
        <hr />
        <h3>Call our customer services team on the number below to speak to one of our advisers who will help you with all of your holiday needs.</h3>

          <div className='date-container'>
            <img src={calender} alt='Date Icon' className='date' />
            <span className='time'>Mon - Sat</span>
          </div>
          <div className='date-container'>
            <img src={time} alt='Time Icon' className='date' />
            <span className='time'>08:30 AM - 05.00 PM</span>
          </div>
          <div className='date-container'>
              <img src={call} alt='call Icon' className='date' />
              <div style={{display:'flex', flexDirection:'column'}}>
              <span className='time'>(+94) 11 2769991</span>
              <span className='time'>(+94) 11 2769992</span>
              </div>
              </div>
        </div>
        {/* <div className='booking'>
            <h1>Need help booking?</h1>
            <hr />
            <h3>Call our customer services team on the number below to speak to one of our advisers who will help you with all of your holiday needs.</h3>
            <div className='date-container'>
              <img src={call} alt='call Icon' className='date' />
              <div style={{display:'flex', flexDirection:'column'}}>
              <span className='time'>(+94) 11 2769991</span>
              <span className='time'>(+94) 11 2769992</span>
              </div>
          </div>
        </div> */}
        <div className='most-popular'>
        <h1>Popular Out Bound Tours</h1>
            <hr />
            <div className='popular'>
            
              <div className='date-container' onClick={handleNavigateToDambadiva} style={{ cursor: 'pointer' }}>
                <img src={Varanasi} alt='Varanasi' className='most-img' />
                <p className='time'>DAMBADIVA PILGRIMAGE</p>
              </div>
              <div className='date-container' onClick={handleNavigateToShimla} style={{ cursor: 'pointer' }}>
                <img src={Shimla} alt='Shimla' className='most-img' />
                <p className='time'>SHIMLA - MANALI - CHANDIGRAH</p>
              </div>
              <div className='date-container' onClick={handleNavigateToKeniya} style={{ cursor: 'pointer' }}>
                <img src={kenya} alt='kenya' className='most-img' />
                <p className='time'>KENYA SAFARI</p>
              </div>
              
              
          </div>
        </div>
      </div>
     </div>
    </>
  )
}

