import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Styles/Splash.css';
import bgvideo from '../Videos/Website-countdown.mp4';

export const Page1 = () => {
  const navigate = useNavigate();

  useEffect(() => {

    const timer = setTimeout(() => {
      navigate('/home');
    }, 7000);


    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <>
      <div className="splash-container">
        <video autoPlay muted id="spalsh-video">
          <source src={bgvideo} type="video/mp4" />
        </video>
      </div>
    </>
  );
};
