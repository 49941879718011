import React, { useState } from 'react';
import Footer from '../Component/Footer'
import Navbar from '../Component/Navbar'
import { useNavigate } from 'react-router-dom';
import '../Styles/ServicePage.css';
import Sigiriya from '../Images/sigiriya-2.jpg';
import kandy from '../Images/kandy.jpg';
import Polanaruwa from '../Images/Polanaruwa.jpg';
import anurathapura from '../Images/anurathapura.jpg';
import galle from '../Images/galle.jpg';
import caption from '../Images/caption.jpg';
import srilanka from '../Images/SRILANKA-1.jpg';
import pada from '../Images/sri-pada.jpg';
import teagarden from '../Images/tea-garden-1.png';
import yala from '../Images/yala.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import bgvideo from '../Videos/srilanka2.mp4';

// const servicesData = [
//   {
//     image: srilanka,
//     title: " Australia & New Zealand In Bound Tour",
//     description: " Ruwanweliseya |  Sigiriya | Nine Arch Bridge",
//     path: "/NewandAus-details"
//   },
  
  
// ];

export const InBound = () => {

  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/NewandAus-details');
  };

  const [showText, setShowText] = useState({
    sigiriya: false,
    polanaruwa: false,
    anuradhapura: false,
    Kandy: false,
    Galle: false,
    Jaffna: false,
    Yala: false,
    NuwaraEliya: false,
    SriPadaya: false,
  });

  const toggleText = (location) => {
    setShowText((prevState) => ({
      ...prevState,
      [location]: !prevState[location],
    }));
  };


  // const handleBookNow = (path) => {
  //   navigate(path);
  // };
  return (
    <>
    <Navbar/>
    <div className="inbound-banner">
        <video autoPlay loop id="background-video">
          <source src={bgvideo} type="video/mp4" />
        </video>
        <div className="inbound-overlay-content">
          <h1>Sri Lanka, Your Dream Destination</h1>


          <h2>Plan Your Trip With Us</h2>
          <h1>Explore the Extraordinary</h1>
        </div>
      </div>


    <div className="inbound-second">
        <h1>In Bound Tours</h1>
        <div className='inbound-second1'>
          <div className='inbound-second2'>
              <img src={srilanka} alt="srilanka" />
          </div>
          <div className='inbound-second3'>
            <h2>Australia & New Zealand In Bound Tour</h2>
            <h3>Ruwanweliseya |  Sigiriya | Nine Arch Bridge</h3>
            <button onClick={handleClick}>Get this plan</button>
          </div>
        </div>
        
        {/* <div className="card">
          {servicesData.map((service, index) => (
            <div
              className="service-card"
              key={index}
              onClick={() => handleBookNow(service.path)}
              style={{ cursor: "pointer" }}
            >
              <div className="service-data">
                <div>
                  <img src={service.image} alt={service.title} />
                </div>
                <div>
                  <h3>{service.title}</h3>
                  <hr />
                  <p>{service.description}</p>
                  <hr />
                </div>
              </div>
            </div>
          ))}
        </div> */}
      </div>

      <div className='our-package'>
          <h1>Popular Tourist Attractions</h1>
          <div className='package-content'>
            <div className='package-main'>
              <div className='package-image'>
              <img src={Sigiriya} alt="Sigiriya" />
              <h3 className='package-overlay'>Sigiriya</h3>
              </div>
              <button className="sri-btn" onClick={() => toggleText('sigiriya')}>
                {showText.sigiriya ? 'Show Less' : 'Explore More'}
                <FontAwesomeIcon icon={showText.sigiriya ? faChevronUp : faChevronDown} />
              </button>
              
              <h5 className={showText.sigiriya ? 'visible' : 'hidden'}>
                Sigiriya, or Lion Rock, is an ancient fortress in central Sri Lanka known for its frescoes, lion gateway, and stunning views.              </h5>
            </div>
            
            <div className='package-main'>
            <div className='package-image'>
              <img src={Polanaruwa} alt="Polanaruwa" />
              <h3 className='package-overlay'>Polonnaruwa</h3>
              </div>
              <button className="sri-btn" onClick={() => toggleText('polanaruwa')}>
                {showText.polanaruwa ? 'Show Less' : 'Explore More'}
                <FontAwesomeIcon icon={showText.polanaruwa ? faChevronUp : faChevronDown} />
              </button>
              <h5 className={showText.polanaruwa ? 'visible' : 'hidden'}>
                Polonnaruwa, Sri Lanka's 11th-12th century capital, features rock-carved Buddha statues and ancient ruins in a lush setting.
              </h5>
            </div>

            <div className='package-main'>
            <div className='package-image'>
              <img src={anurathapura} alt="Anuradhapura" />
              <h3 className='package-overlay'>Anuradhapura</h3>
              </div>
              <button className="sri-btn" onClick={() => toggleText('anuradhapura')}>
                {showText.anuradhapura ? 'Show Less' : 'Explore More'}
                <FontAwesomeIcon icon={showText.anuradhapura ? faChevronUp : faChevronDown} />
              </button>
              <h5 className={showText.anuradhapura ? 'visible' : 'hidden'}>
                Anuradhapura, a UNESCO site in northern Sri Lanka, is known for its ancient ruins and sacred sites like the Sri Maha Bodhi tree.
              </h5>
            </div>

            <div className='package-main'>
            <div className='package-image'>
              <img src={kandy} alt="kandy" />
              <h3 className='package-overlay'>Kandy</h3>
              </div>
              <button className="sri-btn" onClick={() => toggleText('Kandy')}>
                {showText.Kandy ? 'Show Less' : 'Explore More'}
                <FontAwesomeIcon icon={showText.Kandy ? faChevronUp : faChevronDown} />
              </button>
              <h5 className={showText.Kandy ? 'visible' : 'hidden'}>
                 Kandy, in Sri Lanka, is known for the Temple of the Sacred Tooth Relic and the Esala Perahera festival, surrounded by lush hills and Kandy Lake.              </h5>
            </div>
            
            <div className='package-main'>
            <div className='package-image'>
              <img src={galle} alt="galle" />
              <h3 className='package-overlay'>Galle</h3>
              </div>
              <button className="sri-btn" onClick={() => toggleText('Galle')}>
                {showText.Galle ? 'Show Less' : 'Explore More'}
                <FontAwesomeIcon icon={showText.Galle ? faChevronUp : faChevronDown} />
              </button>
              <h5 className={showText.Galle ? 'visible' : 'hidden'}>
                Galle, on Sri Lanka's southwest coast, is famous for its historic fort and beautiful beaches like Unawatuna.              </h5>
            </div>

            <div className='package-main'>
            <div className='package-image'>
              <img src={caption} alt="caption" />
              <h3 className='package-overlay'>Jaffna</h3>
              </div>
              <button className="sri-btn" onClick={() => toggleText('Jaffna')}>
                {showText.Jaffna ? 'Show Less' : 'Explore More'}
                <FontAwesomeIcon icon={showText.Jaffna ? faChevronUp : faChevronDown} />
              </button>
              <h5 className={showText.Jaffna ? 'visible' : 'hidden'}>
                Jaffna, in northern Sri Lanka, is a cultural hub known for its Tamil heritage, featuring Jaffna Fort, Nallur Kandaswamy Temple, and tranquil Casuarina Beach.              </h5>
            </div>

            <div className='package-main'>
            <div className='package-image'>
              <img src={yala} alt="yala" />
              <h3 className='package-overlay'>Yala</h3>
              </div>
              <button className="sri-btn" onClick={() => toggleText('Yala')}>
                {showText.Yala ? 'Show Less' : 'Explore More'}
                <FontAwesomeIcon icon={showText.Yala ? faChevronUp : faChevronDown} />
              </button>
              <h5 className={showText.Yala ? 'visible' : 'hidden'}>
                Yala National Park in southeastern Sri Lanka is a renowned wildlife reserve famous for leopards and diverse ecosystems.
              </h5>
            </div>
            
            <div className='package-main'>
            <div className='package-image'>
              <img src={teagarden} alt="teagarden" />
              <h3 className='package-overlay'>NuwaraEliya</h3>
              </div>
              <button className="sri-btn" onClick={() => toggleText('NuwaraEliya')}>
                {showText.NuwaraEliya ? 'Show Less' : 'Explore More'}
                <FontAwesomeIcon icon={showText.NuwaraEliya ? faChevronUp : faChevronDown} />
              </button>
              <h5 className={showText.NuwaraEliya ? 'visible' : 'hidden'}>
                Nuwara Eliya, known as "Little England," is a charming hill town in Sri Lanka famous for its tea plantations and cool climate.              </h5>
            </div>

            <div className='package-main'>
            <div className='package-image'>
              <img src={pada} alt="pada" />
              <h3 className='package-overlay'>Adam's Peak</h3>
              </div>
              <button className="sri-btn" onClick={() => toggleText('SriPadaya')}>
                {showText.SriPadaya ? 'Show Less' : 'Explore More'}
                <FontAwesomeIcon icon={showText.SriPadaya ? faChevronUp : faChevronDown} />
              </button>
              <h5 className={showText.SriPadaya ? 'visible' : 'hidden'}>
                Sri Padaya (Adam's Peak) is a sacred mountain in Sri Lanka known for its footprint and breathtaking views.              </h5>
            </div>
    </div>
      </div>
    <Footer/>
    </>
  )
}
