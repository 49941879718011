import React from 'react';
import '../Styles/ServiceCard.css'; 
import inbound from '../Images/inbound.jpg';
import outbound from '../Images/outbound.jpg';
import plane from '../Images/plane.png';

const ServiceCard = () => {
  
  return (
    <div className="service-card-container">
      <a href="/out-bound-tour" className="servicecard">
          <img src={outbound} alt="Out-Bound Tours" className="service-card-img" />
          <div className="overlay-text">Out Bound Tours</div>
          <div className="hover-text">Explore the world with Sagami Travels</div>
          <img src={plane} alt="plane" className='overlay-img' style={{width:'50px'}} />
        </a>

        <a href="/in-bound-tour" className="servicecard">
          <img src={inbound} alt="In-Bound Tours" className="service-card-img" />
          <div className="overlay-text">In Bound Tours</div>
          <div className="hover-text">Explore the beauty of Sri Lanka with Sagami Travels</div>
          <img src={plane} alt="plane" className='overlay-img' style={{width:'50px'}} />
        </a>

    </div>
  );
};

export default ServiceCard;
