import React, { useState, useEffect } from 'react';
import '../Styles/Navbar.css';
import logo from '../Images/sagami-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleDropdownToggle = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <nav className={`navbar ${isScrolled ? 'scrolled' : ''}`}>
            <div className="logo">
                <Link to="/home">
                    <img src={logo} alt="Logo" />
                </Link>
                </div>
            <div className={`menu-links ${isMobile ? 'active' : ''}`}>
                <a href="/home">Home</a>
                <a href="/about">About</a>
                
                {/* Services dropdown */}
                <div className="dropdown">
                    <a href="#services" onClick={handleDropdownToggle}>
                        Services
                    </a>
                    {isDropdownOpen && (
                        <div className="dropdown-menu">
                            <a href="/out-bound-tour">Out Bound</a>
                            <a href="/in-bound-tour">In Bound</a>
                            
                            <a href="/other-services">Other Services</a>
                        </div>
                    )}
                </div>

                <a href="/contact">Contact</a>
            </div>
            <div className="menu-icon" onClick={() => setIsMobile(!isMobile)}>
                <FontAwesomeIcon icon={isMobile ? faTimes : faBars} />
            </div>
        </nav>
    );
};

export default Navbar;
