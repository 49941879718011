import React from 'react'
import Navbar from '../Component/Navbar'
import '../Styles/Other.css'; 
import local from '../Images/local.jpg';
import corporate from '../Images/corporate.jpg';
import airline from '../Images/airline.png';
import visa from '../Images/visa.jpg';
import plane from '../Images/plane.png';

export const OtherService = () => {
  return (
    <>
      <Navbar/>
      <div className='other-container'>
        <h2>Other Services</h2>
        <div className='other-service'>
            <a href="/local-tour" className="othercard">
                <img src={local} alt="Local Tours" className="other-card-img" />
                <div className="other-overlay-text">Local Tours</div>
                <div className="other-hover-text">Explore tours within Sri Lanka and enjoy the beauty of this country with Sagami Travels.</div>
                <img src={plane} alt="plane" className='overlay-img' style={{width:'50px'}} />
              </a>

              <a href="/local-tour" className="othercard1">
                <img src={corporate} alt="Out-Bound Tours" className="other-card-img" />
                <div className="other-overlay-text">Cooperate Tours</div>
                <div className="other-hover-text">Need to plan your business tours? Contact Sagami Travels for the best tour packages!</div>
                <img src={plane} alt="plane" className='overlay-img' style={{width:'50px'}}/>
              </a>

              <a href="/local-tour" className="othercard1">
                <img src={airline} alt="Out-Bound Tours" className="other-card-img" />
                <div className="other-overlay-text">Airline Ticketing</div>
                <div className="other-hover-text">Enjoy the best airline ticketing prices in the market with Sagami Travels.</div>
                <img src={plane} alt="plane" className='overlay-img' style={{width:'50px'}}/>
              </a>              

              <a href="/local-tour" className="othercard">
                <img src={visa} alt="Out-Bound Tours" className="other-card-img" />
                <div className="other-overlay-text">Visa Services</div>
                <div className="other-hover-text">All your Visa Services under one roof at Sagami Travels.</div>
                <img src={plane} alt="plane" className='overlay-img' style={{width:'50px'}}/>
              </a>
          </div>
      </div>
    </>
  )
}
