import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../Styles/Detail/DetailsPage.css';
import Navbar from '../../Component/Navbar'
import calender from '../../Images/calender.png';
import time from '../../Images/time.png';
import call from '../../Images/call.png';
import Kataragama from '../../Images/Kataragama.jpg';
import sembuwatta from '../../Images/sembuwatta.jpg';
import arugam from '../../Images/arugam-bay.jpg';
import kataragama2 from '../../Images/kataragama2.jpg';
import birdspark from '../../Images/birdspark.jpg';
import conatctcall from '../../Images/contact-call.png';

export const KataragamaDetailsPage = () => {
  const handleClick = () => {
    const phoneNumber = '+94777790910'; 
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=Hello,%20I%20would%20like%20to%20get%20more%20details%20about%20your%20services.`; // Message to pre-fill

    window.open(whatsappUrl, '_blank'); 
  };

  
  const navigate = useNavigate(); 

  const handleNavigateToSembuwatta = () => {
    navigate('/sembuwata-details'); 
  };



  return (
    <>
     <Navbar/>
     <div className='details-section'>


      <div className='mainbar'>
        <img src={Kataragama} alt="Kataragama" />
        <div className='details'>
            <h1>East Coast & Kataragama</h1>
            <h2>Kataragama | Kithala | Bird Park</h2>
            <hr/>
        </div>
        <div className='content'>
            <h3>About Our Kataragama Trip</h3>

            <div className='first-day'>
              <h4>1st Day</h4>
              <div className='content-data'>
                <h5>Morning 06.30am. from Kohuwala, Sagami Office. Travel via Kahathuduwa E1 Southern expressway to Lunugamvehera, Wellawaya, Monaragala to Arugam Bay</h5>
                <img src={arugam} alt="arugam" />
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>2nd Day</h4>
              <div className='content-data'>
                <img src={kataragama2} alt="kataragama2" />
                <h5>Travel via Buttala to Kataragama, Tissamaharagama area. Stay at Kithala Hotel or similar. Visit to Kataragama.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>3rd Day</h4>
              <div className='content-data'>
                <h5>Trvale via Bird Park, Hambantota to Colombo on the E1 with a stop @Welipenna</h5>
                <img src={birdspark} alt="birdspark" />
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>
        </div>
        <div className='more-button'>
              <h3>Get more Deatils Contact Us</h3>
              <div onClick={handleClick} className="myButton">
                <h6>Call Us</h6>
                <img src={conatctcall} alt="conatct-call" className='conatct-call'/>
            </div>
        </div>
      </div>


      <div className='sidebar'>
      <div className='time-date'>
        <h1>Need help booking?</h1>
        <hr />
        <h3>Call our customer services team on the number below to speak to one of our advisers who will help you with all of your holiday needs.</h3>

          <div className='date-container'>
            <img src={calender} alt='Date Icon' className='date' />
            <span className='time'>Mon - Sat</span>
          </div>
          <div className='date-container'>
            <img src={time} alt='Time Icon' className='date' />
            <span className='time'>08:30 AM - 05.00 PM</span>
          </div>
          <div className='date-container'>
              <img src={call} alt='call Icon' className='date' />
              <div style={{display:'flex', flexDirection:'column'}}>
              <span className='time'>(+94) 11 2769991</span>
              <span className='time'>(+94) 11 2769992</span>
              </div>
              </div>
        </div>
        {/* <div className='booking'>
            <h1>Need help booking?</h1>
            <hr />
            <h3>Call our customer services team on the number below to speak to one of our advisers who will help you with all of your holiday needs.</h3>
            <div className='date-container'>
              <img src={call} alt='call Icon' className='date' />
              <span className='time'>0778 133 133</span>
          </div>
        </div> */}
        <div className='most-popular'>
            <h1>Popular Local Tours</h1>
            <hr />
            <div className='popular'>
              <div className='date-container' onClick={handleNavigateToSembuwatta} style={{ cursor: 'pointer' }}>
                <img src={sembuwatta} alt='japan' className='most-img' />
                <p className='time'>Sembuwatta</p>
                

              </div>
              
              
          </div>
        </div>
      </div>
     </div>
    </>
  )
}

