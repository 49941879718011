import React, { useState } from 'react';
import '../Styles/Splash.css';
import { useNavigate } from 'react-router-dom';
import bgvideo from '../Videos/Opening -Sagami.mp4';

export const SplashPage = () => {

  const navigate = useNavigate();

  
  const [isFading, setIsFading] = useState(false);

  const handleButtonClick = () => {
    setIsFading(true); 
    setTimeout(() => {
      navigate('/count'); 
    }, 1000); 
  };

  return (
    <>
      <div className={`splash-container ${isFading ? 'fade-out' : ''}`}>
        <video autoPlay muted loop id="spalsh-video">
          <source src={bgvideo} type="video/mp4" />
        </video>
        <div className="overlay-splah">
          <button onClick={handleButtonClick}>Start Your Journey</button>
        </div>
      </div>
    </>
  );
};
