import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../Styles/ServicePage.css';
import Navbar from '../Component/Navbar';
import Kataragama from '../Images/Kataragama.jpg';
import sembuwatta from '../Images/sembuwatta.jpg';
import Footer from '../Component/Footer';


const servicesData = [
  {
    image: Kataragama,
    title: "East Coast & Kataragama",
    description: "Kataragama | Kithala | Bird Park",
    path: "/kataragama-details"
  },
  {
    image: sembuwatta,
    title: "Sembuwatta",
    description: "Sembuwatta Lake | Hunnas Falls",
    path: "/sembuwata-details"
  },
  
];

export const LocalTourPage = () => {
  const navigate = useNavigate();

  const handleBookNow = (path) => {
    navigate(path);
  };

  return (
    <>
      <Navbar />
      <div className="Services">
        <h1>Local Tours</h1>
        
        <div className="card">
          {servicesData.map((service, index) => (
            <div
              className="service-card"
              key={index}
              onClick={() => handleBookNow(service.path)}
              style={{ cursor: "pointer" }}
            >
              <div className="service-data">
                <div>
                  <img src={service.image} alt={service.title} />
                </div>
                <div>
                  <h3>{service.title}</h3>
                  <hr />
                  <p>{service.description}</p>
                  <hr />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer/>
    </>
  );
};
