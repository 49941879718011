import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import '../../Styles/Detail/DetailsPage.css';
import Navbar from '../../Component/Navbar'
import calender from '../../Images/calender.png';
import time from '../../Images/time.png';
import call from '../../Images/call.png';
import moscow from '../../Images/moscow.jpg';
import IvolginskyDatsan from '../../Images/IvolginskyDatsan.jpg';
import Nicholas from '../../Images/SteNicholas.jpg';
import Yekaterinburg from '../../Images/Yekaterinburg.jpg';
import conatctcall from '../../Images/contact-call.png';
import alexandria from '../../Images/alexandria.png';
import Jakarta from '../../Images/Jakarta.jpg';
import Baku from '../../Images/Baku.jpg';


export const TranssiberianDetailsPage = () => {
  const handleClick = () => {
    const phoneNumber = '+94777790910'; 
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=Hello,%20I%20would%20like%20to%20get%20more%20details%20about%20your%20services.`; // Message to pre-fill

    window.open(whatsappUrl, '_blank'); 
  };

  
  const navigate = useNavigate(); 

  const handleNavigateToBaku = () => {
    navigate('/baku-gabala-details'); 
  };
  const handleNavigateToAlex = () => {
    navigate('/egypt-details'); 
  };
  const handleNavigateToJakarta = () => {
    navigate('/jakarta-details'); 
  };


  return (
    <>
     <Navbar/>
     <div className='details-section'>


     <div className='mainbar'>
        <img src={moscow} alt="moscow" />
        <div className='details'>
            <h1>TRANSSIBERIAN ADVENTURE TOUR</h1>
            <h2>Moscow | Ulan-Ude | Irkutsk | Listvyanka | Yekaterinburg</h2>
            <hr/>
        </div>
        <div className='content'>
            <h3>About Our TRANSSIBERIAN ADVENTURE Tour</h3>

            <div className='first-day'>
              <h4>Day 01 : Arrival in Mascow</h4>
              <div className='content-data1'>
                <ul>
                    <li><h5 style={{margin:'0px'}}>Arrival at Moscow by FZ 989 at 15:00</h5></li>
                    <li><h5 style={{margin:'0px'}}>Transfer to the hotel</h5></li>
                    <li><h5 style={{margin:'0px'}}>Check in Hotel</h5></li>
                    <li><h5 style={{margin:'0px'}}>18:00 Dinner at hotel</h5></li>
                    <li><h5 style={{margin:'0px'}}>Overnight</h5></li>
                </ul>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 02 : Moscow - flight to Ulan-Ude</h4>
              <div className='content-data1'>
                <ul>
                    <li><h5 style={{margin:'0px'}}>Breakfast. Meeting guide at lobby</h5></li>
                    <li><h5 style={{margin:'0px'}}>Check out</h5></li>
                    <li><h5 style={{margin:'0px'}}>Moscow city tour</h5></li>
                    <li><h5 style={{margin:'0px'}}>Kremlin visit</h5></li>
                    <li><h5 style={{margin:'0px'}}>Local Lunch</h5></li>
                    <li><h5 style={{margin:'0px'}}>Moscow Metro tour</h5></li>
                    <li><h5 style={{margin:'0px'}}>Indian Dinner</h5></li>
                    <li><h5 style={{margin:'0px'}}>Transfer to the airport</h5></li>
                    <li><h5 style={{margin:'0px'}}>Flight To Ulan Ude at 22:20, Flight S73033</h5></li>
                </ul>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 03 : Ulan-Ude</h4>
              <div className='content-data'>
                <img src={IvolginskyDatsan} alt="IvolginskyDatsan" />
                <ul>
                    <li><h5 style={{margin:'0px'}}>9:10 Arrival in Ulan-Ude.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Transfer to hotel.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Early check in at hotel.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Breakfast at the hotel.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Bus sightseeing tour around Ulan-Ude: IvolginskyDatsan, the cultural center of Tarbagati.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Excursion to IvolginskyDatsan.</h5></li>
                </ul>            </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 04 : Ulan-Ude-Irkutsk</h4>
              <div className='content-data1'>
              <ul>
                    <li><h5 style={{margin:'0px'}}>Breakfast at the hotel and check out.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Transfer to the train station.</h5></li>
                    <li><h5 style={{margin:'0px'}}>We will take an early train to get to Irkutsk (07:20).</h5></li>
                    <li><h5 style={{margin:'0px'}}>Arrival in Irkutsk at 15:08 and Meeting with a guide</h5></li>
                    <li><h5 style={{margin:'0px'}}>Transfer from the train station, Check in and Lunch at the hotel.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Bus sightseeing tour around Irkutsk.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Dinner at the hotel.</h5></li>
                </ul>  
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 05 : Irkutsk-Listvyanka</h4>
              <div className='content-data1'>
                <h5 style={{textAlign:'right'}}>After breakfast and hotel check-out, take a group excursion on the motor ship "Barguzin" along the Circum-Baikal Railway. Enjoy excursions at Cape Tolstoy and Cape Polovinny before disembarking in Listvyanka. Transfer to your hotel and have dinner at "Mayak" restaurant. </h5>  
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 06 : Listvyanka - Night tarin to Kransboyarsk</h4>
              <div className='content-data1'>
                <h5>After visiting Listvyanka's seal aquarium, Baikal museums, and Taltsy Museum of Wooden Architecture, enjoy lunch and take a ropeway to a viewpoint. Transfer to the train station for the journey to Krasnoyarsk.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 07 : Krasnoyarsk</h4>
              <div className='content-data'>
                <img src={Nicholas} alt="Nicholas" />
                <ul>
                    <li><h5 style={{margin:'0px'}}>Arrive and Transfer to hotel.</h5></li>
                    <li><h5 style={{margin:'0px'}}>early check in and Breakfast at the hotel.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Bus sightseeing tour around Krasnoyarsk: the Paraskeva Pyatnitsa chapel and a viewpoint, the area of the old jail, The Museum of a Steamboat "Saint Nicholas"</h5></li>
                    <li><h5 style={{margin:'0px'}}>take a 1 hr boat trip on the great Yenisei</h5></li>

                </ul>
                </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 08 : Krasnoyarsk - Novosibirsk</h4>
              <div className='content-data1'>
                <ul>
                    <li><h5 style={{margin:'0px'}}>Breakfast and Check out at hotel.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Excursion in mountain reserve "Stolby".</h5></li>
                    <li><h5 style={{margin:'0px'}}>Lunch ata the Siberian Restaurant.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Visiting the Hydro Power Station.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Visiting Divnogorsk.</h5></li>
                    <li><h5 style={{margin:'0px'}}>In the evening we will go to the railway station and take a train #001 to Novosibirsk depart at 21:10.</h5></li>
                    <li><h5 style={{margin:'0px'}}>you will have on the train.</h5></li>
                </ul>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 09 : Krasnoyarsk-Novosibirsk- night train to Omsk</h4>
              <div className='content-data1'>
                <h5>After arriving in Novosibirsk at 09:30, enjoy a city tour, including Lenin Square and the Opera House. Visit Novosibirsk Zoo, followed by five hours at the "Akvamir" aqua park. After dinner, transfer to the station for the night train to Omsk.</h5>
                </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 10: Omsk - Yekaterinburg</h4>
              <div className='content-data'>            
              <ul>
                    <li><h5 style={{margin:'0px'}}>Arrive in Omsk.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Transfer for buffet breakfast.</h5></li>
                    <li><h5 style={{margin:'0px'}}>City Tour</h5></li>
                    <li><h5 style={{margin:'0px'}}>Russian banya</h5></li>
                    <li><h5 style={{margin:'0px'}}>Lunch in Siberian Restaurant.</h5></li>
                    <li><h5 style={{margin:'0px'}}>Tranfer by train #001 departing from Omsk to Yekaterinburg</h5></li>
                </ul>  
                <img src={Yekaterinburg} alt="Yekaterinburg" />
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 11 : Yekaterinburg</h4>
              <div className='content-data1'>
                <h5>Arrive in Yekaterinburg at 05:23 and transfer to the hotel for check-in and breakfast. After resting, enjoy a local lunch followed by a tour of the Boris Eltsin Museum and an art gallery at the expo center. Finish the day with a local dinner and return to the hotel.</h5>
                </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 12 : Yekaterinburg</h4>
              <div className='content-data1'>
                <h5>Bus Sightseeing tour around Ekaterinburg: the Church on Blood, Regional Museum then Lunch in the restaurant and Transfer to the airport.</h5>
                </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

        </div>


        <div className='more-button'>
              <h3>Get more Deatils Contact Us</h3>
              <div onClick={handleClick} className="myButton">
                <h6>Call Us</h6>
                <img src={conatctcall} alt="conatct-call" className='conatct-call'/>
            </div>
        </div>
      </div>


      <div className='sidebar'>
      <div className='time-date'>
        <h1>Need help booking?</h1>
        <hr />
        <h3>Call our customer services team on the number below to speak to one of our advisers who will help you with all of your holiday needs.</h3>

          <div className='date-container'>
            <img src={calender} alt='Date Icon' className='date' />
            <span className='time'>Mon - Sat</span>
          </div>
          <div className='date-container'>
            <img src={time} alt='Time Icon' className='date' />
            <span className='time'>08:30 AM - 05.00 PM</span>
          </div>
          <div className='date-container'>
              <img src={call} alt='call Icon' className='date' />
              <div style={{display:'flex', flexDirection:'column'}}>
              <span className='time'>(+94) 11 2769991</span>
              <span className='time'>(+94) 11 2769992</span>
              </div>
              </div>
        </div>
        {/* <div className='booking'>
            <h1>Need help booking?</h1>
            <hr />
            <h3>Call our customer services team on the number below to speak to one of our advisers who will help you with all of your holiday needs.</h3>
            <div className='date-container'>
              <img src={call} alt='call Icon' className='date' />
              <div style={{display:'flex', flexDirection:'column'}}>
              <span className='time'>(+94) 11 2769991</span>
              <span className='time'>(+94) 11 2769992</span>
              </div>
          </div>
        </div> */}
        <div className='most-popular'>
        <h1>Popular Out Bound Tours</h1>
            <hr />
            <div className='popular'>
              
              <div className='date-container' onClick={handleNavigateToAlex} style={{ cursor: 'pointer' }}>
                <img src={alexandria} alt='alexandria' className='most-img' />
                <p className='time'>EGYPT TOUR 08N/09D</p>
              </div>
              <div className='date-container' onClick={handleNavigateToJakarta} style={{ cursor: 'pointer' }}>
                <img src={Jakarta} alt='Jakarta' className='most-img' />
                <p className='time'>JAKARTA - YOGYAKARTA - BALI</p>
              </div>
              <div className='date-container' onClick={handleNavigateToBaku} style={{ cursor: 'pointer' }}>
                <img src={Baku} alt='Baku' className='most-img' />
                <p className='time'>BAKU & GABALA</p>
              </div>
              
          </div>
        </div>
      </div>
     </div>
    </>
  )
}

