import React from 'react'
import Navbar from '../Component/Navbar'
import '../Styles/About.css';
// import about1 from '../Images/about1.jpg';
// import about2 from '../Images/about2.png';
// import exclusive from '../Images/exclusive.png';
// import safety from '../Images/safety.png';
// import guide from '../Images/guide.png';
// import aboutoverlay from '../Images/aboutoverlay.png';
import transportation from '../Images/transportation.jpg';
import travelinsurance from '../Images/travelinsurance.jpg';
import story from '../Images/story.png';
import gamini from '../Images/gamini.jpg';
import Footer from '../Component/Footer';
import down from '../Images/down.png';
import up from '../Images/up.png';
import Susanthan from '../Images/Susanthan.jpg';
import Shangheetha from '../Images/Shangheetha.jpg';
import Dharshani from '../Images/Dharshani.jpg';
import budula from '../Images/budula.jpg';
import sathesh from '../Images/sathesh.jpg';

export const AboutPage = () => {
    const teamData = [
      {
        image: Dharshani,
        title: "Dharshani Chandrasekera",
        des:"CEO's wife"
      },
        {
          image: Susanthan,
          title: "Velautham Susanthan",
          des:"General Manager"
        },
        {
          image: budula,
          title: "Bandula de Silva",
          des:"In bound Manager"
        },
        
        {
          image: Shangheetha,
          title: "Shangeetha Jeyaseelan",
          des:"Finance Manager"
        },
        {
          image: sathesh,
          title: "Sathis Spencer",
          des:"Marketing Manager"
        },
        
      ];
      
  return (
    <>
    <Navbar/>
    <div className='about-banner'>
        <h1>ABOUT US</h1>
    </div>

    <div className='story'>
      <div className='story-text'>
        <h3>Our Story</h3>
        <h2>
        Sagami Travels, a subsidiary of Sagami International, originally focused on public tourism and transport but has expanded into the tourism and hospitality industry in Sri Lanka. With over 30 years of experience and an SLTPB authorization, their goal is to provide reliable, comfortable, and high-quality travel services both locally and internationally.
        <br /><br />
        The company teams both inbound and outbounds tours, specializing in destinations like Japan, where they've organized successful tours to major cities such as Tokyo, Hiroshima, and Kyoto. They also pioneered the Snow Japan University of Hokkaido Tour in Sri Lanka. Sagami's office is in Nugegoda, Colombo, with a well-trained and professional staff dedicated to hospitality services.
        <br /><br />
        Sagami's success is attributed to its customer-centric approach, led by Managing Director Gamini Chandrasekera, a former national rugby player. In 2014, they launched the Sagami Travel Club to provide frequent travelers with exclusive benefits, further expanding their teamings to new destinations like Europe, Australia, and Southeast Asia.
        <br /><br />
        The company is committed to providing unmatched travel experiences and aims to build long-term trust with clients, as evidenced by positive feedback from customers.
        </h2>
      </div>
      <img src={story} alt="story" />
    </div>

    <div className='ceo'>
    <h1>Message from Our Leader</h1>
    <div className='ceo-text'>
        <div className='ceo-img'>
            <img src={gamini} alt="gamini" />
        </div>
      
          
        <div className='ceo-text2'>
          <h3>THE WORLD IS A BOOK & THOSE WHO DO NOT TRAVEL READ ONLY ONE PAGE</h3>
 
            <h4>I, Gamini Chandrasekera, tapestry woven for various experiences. I carry with me the essence of culture, values & lessons I got from Japan while I was living there.
              
            My passion for travelling was nurtured in opening of Sagami Travels 15 years back. All these long years I gave all my travelers the best of the best. I make all our tours to educational tours. This has made Sagami the Market Leader of Japan Tours.
              
            The happiness is a way of travel not a destination. Keeping this in mind I always treat my travelers with a good heart & try to make them comfortable & happy always. Since we cover 18 destinations I invite all of you to join Sagami to explore the Planet Earth.</h4>
              
           <h3>Gamini Chandrasekera,<br />
            CEO,<br />
            Sagami Travels & Tours</h3> 
            <img src={up} alt="up" className='up'/>
            <img src={down} alt="down" className='down'/>
      </div>
      </div>

    </div>

    {/* <div className='go-together'>
        <div className='together-img'>
            <img src={about2} alt="about-img" className='about2'/>
            <img src={about1} alt="about-img" className='about1'/>   
        </div>
        <div className='together-text'>
            <h3>Let’s go together</h3>
            <h1>Plan Your Trip With us</h1>
            <p>There are many variations of passages of available but the majority have suffered alteration in some form, by injected hum randomised words which don’t look even slightly.</p>
            <div className='together-part'>
                <div className='together-part1'>
                    <img src={exclusive} alt="about-img" className='about3'/>
                    <div className='together-inside'>
                        <h2>Exclusive Trip</h2>
                        <p>There are many variations of passages of available but the majority.</p>
                    </div>
                </div>
                <div className='together-part2'>
                    <img src={safety} alt="about-img" className='about3'/>
                    <div className='together-inside'>
                        <h2>Safety First Always</h2>
                        <p>There are many variations of passages of available but the majority.</p>
                    </div>
                </div>
                <div className='together-part1'>
                    <img src={guide} alt="about-img" className='about3'/>
                    <div className='together-inside'>
                        <h2>Professional Guide</h2>
                        <p>There are many variations of passages of available but the majority.</p>
                    </div>
                </div>    
            </div>  
        </div>
        <img src={aboutoverlay} alt="about-overlay" className='about-overlay'/>
    </div> */}

    {/* <div className='team'>
        <h3>Our Team</h3>
        <h1>Meet with our expert Team</h1>
        <div className="team-card">
          {teamData.map((team, index) => (
            <div className="team-inner" key={index}>
              <img src={team.image} alt={team.title} />
              <div className='iner-text'>
              <h4>{team.title}</h4>
              <h6>{team.des}</h6>
              </div>
            </div>
          ))}
        </div>
    </div> */}

    <Footer/>
    </>
  )
}
