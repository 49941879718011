import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import '../../Styles/Detail/DetailsPage.css';
import Navbar from '../../Component/Navbar'
import calender from '../../Images/calender.png';
import time from '../../Images/time.png';
import call from '../../Images/call.png';
import Darling from '../../Images/Darling-Harbor.jpg';
import china from '../../Images/china.jpg';
import Aquarium from '../../Images/Sydney-Aquarium.jpg';
import Launceston from '../../Images/Launceston-Harbor.jpg';
import Eureka from '../../Images/Eureka-tower.jpg';
import conatctcall from '../../Images/contact-call.png';
import sydeny from '../../Images/sydeny.jpg';
import departure from '../../Images/flight-departure.jpg';
import moscow from '../../Images/moscow.jpg';
import alexandria from '../../Images/alexandria.png';


export const SydenyDetailsPage = () => {
  const handleClick = () => {
    const phoneNumber = '+94777790910'; 
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=Hello,%20I%20would%20like%20to%20get%20more%20details%20about%20your%20services.`; // Message to pre-fill

    window.open(whatsappUrl, '_blank'); 
  };

  
  const navigate = useNavigate(); 

  const handleNavigateToMoscow = () => {
    navigate('/transsiberian-details'); 
  };
  const handleNavigateToAlex = () => {
    navigate('/egypt-details'); 
  };
  const handleNavigateToChina = () => {
    navigate('/china-details'); 
  };

  return (
    <>
     <Navbar/>
     <div className='details-section'>


      <div className='mainbar'>
        <img src={sydeny} alt="sydeny" />
        <div className='details'>
            <h1>SYDENY & TASMANIAN & MALBOURE</h1>
            <h2>Sydeny | Tasmanian | Malboure</h2>
            <hr/>
        </div>
        <div className='content'>
            <h3>About Our SYDENY & TASMANIAN & MALBOURE Trip</h3>

            <div className='first-day'>
              <h4>Day 01</h4>
              <div className='content-data1'>            
                <h5>Arrive to Sydney, Checking to Hotel Sydney</h5> 
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 02</h4>
              <div className='content-data'>
                <img src={Darling} alt="Darling" />
                <h5>visit Opera House/ China Town/ Beautiful Darling Harbor including City Tour.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 03</h4>
              <div className='content-data1'>            
              <h5>After Breakfast Visit Blue Mt/ Scenic Railway/ Cable Car/ Feathery Dale wild life zoo including Tasmanian Devils.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 04</h4>
              <div className='content-data'>
                <img src={Aquarium} alt="Aquarium" />
                <h5>After Breakfast visit the Sydney Aquarium and landing in cruise Darling harbor departure to Tasmania by Jet star internal flight.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 05</h4>
              <div className='content-data1'>            
              <h5>After Breakfast Visit Port Arthur to see the british prison & 01 settlers colony. </h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 06</h4>
              <div className='content-data1'>
                <h5 style={{textAlign:'right'}}>After Breakfast Visit Kuringa Farm/ Sheep Shearing/ Dog Show/ B,B,Q Lunch onthe way visit winery/wonderful panorama view from Mt.Wiellington to see Hobart City. </h5>  
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 07</h4>
              <div className='content-data'>            
                <h5>After Breakfast visit Board for Tasmanian Cruise to Launceston Harbor at 6.30 PM with and adventurous sall tour via bass stright and on the way visit the Honey Farm/Lavender Farm. (Reach Melbourne 6.30 AM)</h5> 
                <img src={Launceston} alt="Launceston" />
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 08</h4>
              <div className='content-data'>
                <img src={Eureka} alt="Eureka" />
                <h5>After Breakfast visit Melbourne Cricket Stadium later Climb on top of the Eureka tower to see a panoramic view of Melbourne city/Phillip Island with Penguins parade.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 09</h4>
              <div className='content-data1'>            
                <h5>After Breakfast visit Victoria market shopping and visit Ballarat Gold Mines/Gold Museum/ Final Dinner at Resturant.</h5> 
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 10</h4>
              <div className='content-data1'>
                <h5 style={{textAlign:'right'}}>After Breakfast Leisure Day Visit your relations last day shopping. On this Day we only provide Breakfast, Lunch and Dinner is on your own cost.</h5>  
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 11</h4>
              <div className='content-data'>            
                <h5>After Breakfast Leave Melbourne to Colombo</h5> 
                <img src={departure} alt="departure" />
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>
            
        </div>
        <div className='more-button'>
              <h3>Get more Deatils Contact Us</h3>
              <div onClick={handleClick} className="myButton">
                <h6>Call Us</h6>
                <img src={conatctcall} alt="conatct-call" className='conatct-call'/>
            </div>
        </div>
      </div>


      <div className='sidebar'>
      <div className='time-date'>
        <h1>Need help booking?</h1>
        <hr />
        <h3>Call our customer services team on the number below to speak to one of our advisers who will help you with all of your holiday needs.</h3>

          <div className='date-container'>
            <img src={calender} alt='Date Icon' className='date' />
            <span className='time'>Mon - Sat</span>
          </div>
          <div className='date-container'>
            <img src={time} alt='Time Icon' className='date' />
            <span className='time'>08:30 AM - 05.00 PM</span>
          </div>
          <div className='date-container'>
              <img src={call} alt='call Icon' className='date' />
              <div style={{display:'flex', flexDirection:'column'}}>
              <span className='time'>(+94) 11 2769991</span>
              <span className='time'>(+94) 11 2769992</span>
              </div>
              </div>
        </div>
        {/* <div className='booking'>
            <h1>Need help booking?</h1>
            <hr />
            <h3>Call our customer services team on the number below to speak to one of our advisers who will help you with all of your holiday needs.</h3>
            <div className='date-container'>
              <img src={call} alt='call Icon' className='date' />
              <div style={{display:'flex', flexDirection:'column'}}>
              <span className='time'>(+94) 11 2769991</span>
              <span className='time'>(+94) 11 2769992</span>
              </div>
          </div>
        </div> */}
        <div className='most-popular'>
        <h1>Popular Out Bound Tours</h1>
            <hr />
            <div className='popular'>
            <div className='date-container' onClick={handleNavigateToChina} style={{ cursor: 'pointer' }}>
                <img src={china} alt='china' className='most-img' />
                <p className='time'>CHINA TOUR</p>
              </div>
              <div className='date-container' onClick={handleNavigateToMoscow} style={{ cursor: 'pointer' }}>
                <img src={moscow} alt='moscow' className='most-img' />
                <p className='time'>TRANSSIBERIAN ADVENTURE TOUR</p>
              </div>
              <div className='date-container' onClick={handleNavigateToAlex} style={{ cursor: 'pointer' }}>
                <img src={alexandria} alt='alexandria' className='most-img' />
                <p className='time'>EGYPT TOUR 08N/09D</p>
              </div>
              
          </div>
        </div>
      </div>
     </div>
    </>
  )
}

