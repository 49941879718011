import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import '../../Styles/Detail/DetailsPage.css';
import Navbar from '../../Component/Navbar'
import calender from '../../Images/calender.png';
import time from '../../Images/time.png';
import call from '../../Images/call.png';
import Tian from '../../Images/Tian-anmen.jpg';
import moscow from '../../Images/moscow.jpg';
import alexandria from '../../Images/alexandria.png';
import Terra from '../../Images/Terra-Cotta.webp';
import Buddha from '../../Images/Jade-Buddha.jpg';
import conatctcall from '../../Images/contact-call.png';
import china from '../../Images/china.jpg';
import Jakarta from '../../Images/Jakarta.jpg';

export const ChinaDetailsPage = () => {
  const handleClick = () => {
    const phoneNumber = '+94777790910'; 
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=Hello,%20I%20would%20like%20to%20get%20more%20details%20about%20your%20services.`; // Message to pre-fill

    window.open(whatsappUrl, '_blank'); 
  };

  
  const navigate = useNavigate(); 

  const handleNavigateToMoscow = () => {
    navigate('/transsiberian-details'); 
  };
  const handleNavigateToAlex = () => {
    navigate('/egypt-details'); 
  };
  const handleNavigateToJakarta = () => {
    navigate('/jakarta-details'); 
  };


  return (
    <>
     <Navbar/>
     <div className='details-section'>


      <div className='mainbar'>
        <img src={china} alt="china" />
        <div className='details'>
            <h1>CHINA TOUR 7N/8DAYS</h1>
            <h2>Beijing | Xian | Shanghai | Yuyuan Garden</h2>
            <hr/>
        </div>
        <div className='content'>
            <h3>About Our CHINA Tour</h3>

            <div className='first-day'>
              <h4>Day 01</h4>
              <div className='content-data1'>            
                <h5>On Arrival, You will be met and transferred to hotel by the driver. After a good rest in a comfortable hotel TVH recommended. Overnight at the hotel.</h5> 
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>2nd Day</h4>
              <div className='content-data'>
                <img src={Tian} alt="Tian" />
                <h5>Start with a visit to Tian'anmen Square and the Forbidden City, exploring the imperial palace of 24 emperors. After visiting a pearl factory, head to the Temple of Heaven to experience the harmony between architecture and nature.</h5>
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 03</h4>
              <div className='content-data1'>            
                <h5>In the morning, visit the Jade factory and the Great Wall at Juyong Pass. Stop at Beijing Olympic Park for photos of the Bird's Nest and Water Cube. Overnight at the hotel.</h5> 
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>
        </div>

        <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 04</h4>
              <div className='content-data1'>
                <h5 style={{textAlign:'right'}}>Morning visit the Bejing zoo with Panda. You will be transferred to train station to Xian. On arrival, xian driver will meet you and transfer you to the hotel. Overnight at the hotel. </h5>  
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 05</h4>
              <div className='content-data'>
                <h5>After breakfast, visit the UNESCO-recognized Terra Cotta Warriors Museum, showcasing the underground army of China’s First Emperor. Explore a special art lacquer workshop and enjoy free time shopping at Ancient Style Street. Overnight at the hotel.</h5>
                <img src={Terra} alt="Terra" />
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 06</h4>
              <div className='content-data1'>
                <h5 style={{textAlign:'right'}}>In the morning, visit the Ancient City Walls and the Big Wild Goose Pagoda, a key Buddhist site. In the afternoon, take a bullet train to Shanghai. </h5>  
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4>Day 07</h4>
              <div className='content-data'>
                <h5>Visit the Jade Buddha Temple in Shanghai, home to two exquisite white jade Buddha statues. Explore Yuyuan Garden with its scenic courtyards, followed by a visit to a Silk Factory. Shop along Nanjing Road, famous for its modernity and local crafts, then head to the Bund for a waterfront view. End with an outside view of the iconic Oriental TV Tower.</h5>
                <img src={Buddha} alt="Buddha" />
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='first-day'>
              <h4 style={{textAlign:'right'}}>Day 08</h4>
              <div className='content-data1'>
                <h5 style={{textAlign:'right'}}>Breakfast at the hotel. Get transferred to the airport for final Departure.</h5>  
              </div>
              <hr style={{ width: '100%', color: '#dddddd', border: '1px solid #dddddd' }} />
            </div>

            <div className='more-button'>
              <h3>Get more Deatils Contact Us</h3>
              <div onClick={handleClick} className="myButton">
                <h6>Call Us</h6>
                <img src={conatctcall} alt="conatct-call" className='conatct-call'/>
            </div>
        </div>
      </div>


      <div className='sidebar'>
      <div className='time-date'>
        <h1>Need help booking?</h1>
        <hr />
        <h3>Call our customer services team on the number below to speak to one of our advisers who will help you with all of your holiday needs.</h3>

          <div className='date-container'>
            <img src={calender} alt='Date Icon' className='date' />
            <span className='time'>Mon - Sat</span>
          </div>
          <div className='date-container'>
            <img src={time} alt='Time Icon' className='date' />
            <span className='time'>08:30 AM - 05.00 PM</span>
          </div>
          <div className='date-container'>
              <img src={call} alt='call Icon' className='date' />
              <div style={{display:'flex', flexDirection:'column'}}>
              <span className='time'>(+94) 11 2769991</span>
              <span className='time'>(+94) 11 2769992</span>
              </div>
              </div>
        </div>
        {/* <div className='booking'>
            <h1>Need help booking?</h1>
            <hr />
            <h3>Call our customer services team on the number below to speak to one of our advisers who will help you with all of your holiday needs.</h3>
            <div className='date-container'>
              <img src={call} alt='call Icon' className='date' />
              <div style={{display:'flex', flexDirection:'column'}}>
              <span className='time'>(+94) 11 2769991</span>
              <span className='time'>(+94) 11 2769992</span>
              </div>
          </div>
        </div> */}
        <div className='most-popular'>
        <h1>Popular Out Bound Tours</h1>
            <hr />
            <div className='popular'>
              <div className='date-container' onClick={handleNavigateToMoscow} style={{ cursor: 'pointer' }}>
                <img src={moscow} alt='moscow' className='most-img' />
                <p className='time'>TRANSSIBERIAN ADVENTURE TOUR</p>
              </div>
              <div className='date-container' onClick={handleNavigateToAlex} style={{ cursor: 'pointer' }}>
                <img src={alexandria} alt='alexandria' className='most-img' />
                <p className='time'>EGYPT TOUR 08N/09D</p>
              </div>
              <div className='date-container' onClick={handleNavigateToJakarta} style={{ cursor: 'pointer' }}>
                <img src={Jakarta} alt='Jakarta' className='most-img' />
                <p className='time'>JAKARTA - YOGYAKARTA - BALI</p>
              </div>
              
          </div>
        </div>
      </div>
     </div>
    </>
  )
}

