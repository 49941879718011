import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HomePage } from './Pages/HomePage';
import { OutBound } from './Pages/OutBound';
import { KataragamaDetailsPage } from './Pages/Details/KataragamaDetailsPage';
import { AboutPage } from './Pages/AboutPage';
import { ContactPage } from './Pages/ContactPage';
import { SplashPage } from './Pages/SplashPage';
import { LocalTourPage } from './Pages/LocalTourPage';
import { SembuwataDetailsPage } from './Pages/Details/SembuwataDetailsPage';
import { ChinaDetailsPage } from './Pages/OutBoundDetails/ChinaDetailsPage';
import { OtherService } from './Pages/OtherService';
import { InBound } from './Pages/InBound';
import { GeorigaDetailsPage } from './Pages/OutBoundDetails/GeorigaDetailsPage';
import { SydenyDetailsPage } from './Pages/OutBoundDetails/SydenyDetailsPage';
import { KenyaDetailsPage } from './Pages/OutBoundDetails/KenyaDetailsPage';
import { TranssiberianDetailsPage } from './Pages/OutBoundDetails/TranssiberianDetailsPage';
import { EgyptDetailsPage } from './Pages/OutBoundDetails/EgyptDetailsPage';
import { JakartaDetailsPage } from './Pages/OutBoundDetails/JakartaDetailsPage';
import { DubaiDetailsPage } from './Pages/OutBoundDetails/DubaiDetailsPage';
import { RanthambhoreDetailsPage } from './Pages/OutBoundDetails/RanthambhoreDetailsPage';
import { BakuDetailsPage } from './Pages/OutBoundDetails/BakuDetailsPage';
import { ThrirupathiDetailsPage } from './Pages/OutBoundDetails/ThrirupathiDetailsPage';
import { VietnamDetailsPage } from './Pages/OutBoundDetails/VietnamDetailsPage';
import { DambadivaDetailsPage } from './Pages/OutBoundDetails/DambadivaDetailsPage';
import { ShimlaDetailsPage } from './Pages/OutBoundDetails/ShimlaDetailsPage';
import { Page1 } from './Pages/Page1';
import { JapanDetailsPage } from './Pages/OutBoundDetails/JapanDeatailsPage';
import { NewandAusDetailsPage } from './Pages/InBoundDetails/NewandAusDetailsPage';




function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/count" element={<Page1 />} />
          <Route path="/" element={<SplashPage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/out-bound-tour" element={<OutBound />} />
          <Route path="/in-bound-tour" element={<InBound />} />
          <Route path="/about" element={<AboutPage/>} />
          <Route path="/kataragama-details" element={<KataragamaDetailsPage/>} />
          <Route path="/sembuwata-details" element={<SembuwataDetailsPage/>} />
          <Route path="/china-details" element={<ChinaDetailsPage/>} />
          <Route path="/georiga-details" element={<GeorigaDetailsPage/>} />
          <Route path="/sydeny-details" element={<SydenyDetailsPage/>} />
          <Route path="/kenya-details" element={<KenyaDetailsPage/>} />
          <Route path="/transsiberian-details" element={<TranssiberianDetailsPage/>} />
          <Route path="/egypt-details" element={<EgyptDetailsPage/>} />
          <Route path="/jakarta-details" element={<JakartaDetailsPage/>} />
          <Route path="/baku-gabala-details" element={<BakuDetailsPage/>} />
          <Route path="/dubai-details" element={<DubaiDetailsPage/>} />
          <Route path="/ranthambhore-details" element={<RanthambhoreDetailsPage/>} />
          <Route path="/Thirupathi-details" element={<ThrirupathiDetailsPage/>} />
          <Route path="/vietnam-details" element={<VietnamDetailsPage/>} />
          <Route path="/dambadiva-details" element={<DambadivaDetailsPage/>} />
          <Route path="/shimla-details" element={<ShimlaDetailsPage/>} />
          <Route path="/japan-details" element={<JapanDetailsPage/>} />
          <Route path="/NewandAus-details" element={<NewandAusDetailsPage/>} />
          <Route path="/local-tour" element={<LocalTourPage />} />
          <Route path="/contact" element={<ContactPage/>} />
          <Route path="/other-services" element={<OtherService/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
